<template>
	<div class="list-youji">
		<div class="list-youjia" v-show="false">
			<div class="list-youjiaa" >
				<div class="list-youjiab">
					<!-- 游记轮播图 -->
					<el-carousel :interval="2000" arrow="never" indicator-position="none" :autoplay="autoplay"
						ref="carousel" @change="wangchu">
						<el-carousel-item v-for="(item,index) in url" :key="index">
							<div class="list-youjib">
								<img :src="item.urla" class="list-yjimg">
								<div class="list-youjic">
									<div class="list-youjid">{{item.urlb}}</div>
									<div class="list-youjie">
										<div class="list-youjif">{{item.urlc}}&nbsp;</div>
									</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="list-img">
					<!-- 游记轮播图右边小图<轮播图> -->
					<div class="list-imga" v-for="(item,index) in url" :key="index"
						:class="[wan==index?'list-imgb':'list-imgc']" @mouseenter="btn(index)"
						@mouseleave="bttn(index)">
						<img :src="item.urla" class="list-imgd">
					</div>
				</div>
			</div>
			<div class="list-fabu">
				<!-- 游记右边发布新游记图片 -->
				<div class="">
					<a href="javascript:;"><img src="http://static.woquguo.net/images/write_youji.jpg"></a>
				</div>
				<div class="list-fabuc">
					<!-- 游记右边最新动态 -->
					<div class="list-fabud">最新动态</div>
					<div class="list-fabue" v-for="item in [1,2,3,4]" :key="item">
						<div class="list-fabuf">
							<div class="list-fabug"><a href="">一起飞差旅宝</a></div>
							<div class="list-fabuh">更新了</div>
							<div class="list-fabui"><a href="">卢旺达航空广州航班恢复通知</a></div>
						</div>
						<div class="list-fabuf">
							<div class="list-fabuz">2022-03-09</div>
							<div class="list-fabuk">15:59</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="list-yuan" >
			<div class="list-yuana">
				<!-- 游记-全部游记跟原创游记按钮 -->
				<div class="list-yuanb">全部游记</div>
				<!-- <div class="list-yuanc">
					<a href="">
						<img src="../../assets/youjibi.png" class="list-youjibi">
						<p>写原创游记</p>
					</a>
				</div> -->
			</div>


			<div class="list-fenleii" v-show="false">
				<div class="list-fenlei">
					<!-- 游记-所有分类以下选项卡 -->
					<div class="list-fenleia">所有分类:</div>
					<div class="list-fenleibb">
						<!-- 目的地选项卡头 -->
						<div class="list-fenleib">
							<div class="list-fenleic">
								<!-- 游记-所有分类选项卡中的   目的地 -->
								<div class="list-fenleid">目的地</div>
								<div class="list-fenleie"><img src="../../assets/jiantouxb.png" class="list-fenleif">
									<!-- 下箭头 -->
								</div>
								<div class="list-fenleig"><img src="../../assets/jiantouxia.jpg" class="list-fenleif">
									<!-- 下箭头 -->
								</div>
							</div>
							<div class="list-guo">
								<!-- 游记-所有分类选项卡中的 目的地内容选择 -->
								<div class="list-guonei">
									<div class="list-nei">国内:</div>
									<div class="list-guoneib">
										<div class="list-guoneic" v-for="(item,index) in mudi" :key="index"
											:class="[mudid==index?'list-guoneif':' ']" @click="btnn(index)">
											<div class="">{{item.guo}}</div>
											<div class="">{{item.nei}}</div>
										</div>
									</div>
								</div>
								<div class="list-guoji">
									<div class="list-nei">国际:</div>
									<div class="list-guoneib">
										<div class="list-guoneic" v-for="(item,index) in mudia" :key="index"
											:class="[mudie==index?'list-guoneif':'']" @click="btnnn(index)">
											<div class="">{{item.guo}}</div>
											<div class="">{{item.nei}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 目的地选项卡尾 -->
					</div>
					<div class="list-fenleibb">
						<!-- 玩法选项卡头 -->
						<div class="list-fenleib">
							<div class="list-fenleic">
								<div class="list-fenleid">玩法</div>
								<div class="list-fenleie"><img src="../../assets/jiantouxb.png" class="list-fenleif">
									<!-- 下箭头 -->
								</div>
								<div class="list-fenleig"><img src="../../assets/jiantouxia.jpg" class="list-fenleif">
									<!-- 下箭头 -->
								</div>
							</div>
							<div class="list-guo">
								<div class="list-guoneii">
									<!-- <div class="list-nei">国内:</div> -->
									<div class="list-guoneib">
										<!-- 玩法内容选择 -->
										<div class="list-guoneic" v-for="(item,index) in wanf" :key="index"
											:class="[wanfa==index?'list-guoneif':' ']" @click="btu(index)">
											<div class="">{{item.guo}}</div>
											<div class="">{{item.nei}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 玩法选项卡尾 -->
					</div>
					<div class="list-fenleibb">
						<!-- 月份选项卡头 -->
						<div class="list-fenleib">
							<div class="list-fenleic">
								<div class="list-fenleid">月份</div>
								<div class="list-fenleie"><img src="../../assets/jiantouxb.png" class="list-fenleif">
									<!-- 下箭头 -->
								</div>
								<div class="list-fenleig"><img src="../../assets/jiantouxia.jpg" class="list-fenleif">
									<!-- 下箭头 -->
								</div>
							</div>
							<div class="list-guo">
								<div class="list-guoneii">
									<!-- <div class="list-nei">国内:</div> -->
									<div class="list-guoneibb">
										<!-- 月份内容选择 -->
										<div class="list-guoneic" v-for="(item,index) in yue" :key="index"
											:class="[yuea==index?'list-guoneif':' ']" @click="btuu(index)">
											<div class="">{{item.guo}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 月份选项卡尾 -->
					</div>
					<div class="list-fenleibb">
						<!-- 天数选项卡头 -->
						<div class="list-fenleib">
							<div class="list-fenleic">
								<div class="list-fenleid">天数</div>
								<div class="list-fenleie"><img src="../../assets/jiantouxb.png" class="list-fenleif">
									<!-- 下箭头 -->
								</div>
								<div class="list-fenleig"><img src="../../assets/jiantouxia.jpg" class="list-fenleif">
									<!-- 下箭头 -->
								</div>
							</div>
							<div class="list-guo">
								<div class="list-guoneii">
									<!-- <div class="list-nei">国内:</div> -->
									<div class="list-guoneibb">
										<!-- 天数内容选择 -->
										<div class="list-guoneic" v-for="(item,index) in tian" :key="index"
											:class="[tiana==index?'list-guoneif':' ']" @click="btuuu(index)">
											<div class="">{{item.guo}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 天数选项卡尾 -->
					</div>
					<div class="list-fenleibb">
						<!-- 和谁出行选项卡头 -->
						<div class="list-fenleib">
							<div class="list-fenleic">
								<div class="list-fenleid">和谁出行</div>
								<div class="list-fenleie"><img src="../../assets/jiantouxb.png" class="list-fenleif">
									<!-- 下箭头 -->
								</div>
								<div class="list-fenleig"><img src="../../assets/jiantouxia.jpg" class="list-fenleif">
									<!-- 下箭头 -->
								</div>
							</div>
							<div class="list-guo">
								<div class="list-guoneii">
									<!-- <div class="list-nei">国内:</div> -->
									<div class="list-guoneibb">
										<!-- 和谁出行内容选择 -->
										<div class="list-guoneic" v-for="(item,index) in he" :key="index"
											:class="[hea==index?'list-guoneif':' ']" @click="btuuuu(index)">
											<div class="">{{item.guo}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 和谁出行选项卡尾 -->
					</div>
				</div>
				<div class="list-xuanze">
					<!-- 全部游记-你已选择 -->
					<div class="list-xz">你已选择:</div>
					<div class="list-xza" v-for="(item,index) in xuanze" :key="index">
						<div class="list-xzb">{{item}}:</div>
						<div class="list-xzc">黑龙江</div>
						<div class="list-xzd">
							<img src="../../assets/shanchub.png" class="list-sch">
							<img src="../../assets/shanchuc.jpg" class="list-sc">
						</div>
					</div>
					<div class="list-xze">清空筛选条件</div>
				</div>
			</div>

			<div class="list-fabiao" v-loading="showLoading">
				<div class="list-fb">
					<!-- 全部游记下方 选项卡 最新发表 热门游记 精品游记 -->
					<div class="list-fbc" v-for="(item,index) in fab" :key="index" :class="[faba==index?'list-fbb':'']"
						@click="btnf(index)">
						<div class="">{{item}}</div>
						<div class="" :class="[faba==index?'list-fbd':'']"></div>
					</div>
				</div>
				<div class="">
					<!-- 全部游记下方 选项卡中内容 头 -->
					<div class="list-fbe">
						<div class="list-fbf" v-for="(item,index) in ArticleList" :key="index">
							<div class="list-fbg">
								<a :href="'#/youji/article?id='+item.ID">
									<img :src="item.CoverImagePath" class="list-fbh">
								</a>
								<!-- <div class="list-fbgg">
									<div class="">.....</div>
								</div> -->
							</div>
							<div class="list-fbi">
								<div class="list-fbj">
									<a :href="'#/youji/article?id='+item.ID">{{item.Title}}</a>
								</div>
								<div class="list-fbk">
									<div class="list-fbl">
										<el-avatar :size="35" :src="item.AuthorFacePath">
										</el-avatar>
										<div class="list-fbo">
											<a href="javascript:;">{{item.AuthorName}}</a>
										</div>
									</div>
									<div class="list-fbl list-fbn">
										<img src="../../assets/dianzanb.jpg" class="list-fbm">
										<img src="../../assets/dianzanf.png" class="list-fbmm">
										<div class="list-fbp">{{item.PlacetCount}}</div>
									</div>
								</div>
								<div class="list-fbq">
									<div class="list-fbr">
										<img src="../../assets/yiqifeiyan.png" class="list-gk">
										<div class="list-fbs">{{item.ViewCount}}</div>
									</div>
									<div class="list-fbr">
										<img src="../../assets/pinglu.png">
										<div class="list-fbs">{{item.CommentCount}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 全部游记下方 选项卡中内容 尾 -->
				</div>
			</div>

			<div class="list-fbt" v-if="ArticleList!=null&&ArticleList.length>0">
				<!-- 全部游记下方 选项卡 最新发表 热门游记 精品游记  分页-->
				<el-pagination  background layout="prev, pager, next"
					:total="pageTotal"  :page-size="pageSize" :current-page='pageindex'
					@current-change='handleCurrentChange'>
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	var util = require('@/utils/util.js');
	var yqfCommon = require('@/utils/yqfCommon.js');
	var datehelper = require('@/utils/datehelper.js');
	var staticData = require('@/data/staticData.js');
	//接口	
	var wqgApi = require('@/api/wqgApi.js');
	//组件引用

	//公共属性
	var that;

	export default {
		components: {
			//组件

		},
		data() {
			return {
				id: '',
				title: "社区首页",
				wan: 0,
				autoplay: true,
				List: '',
				OrderBy: 1,
				currentPage: 1,
				pageSizes:20,
				pageSize: 20,
				pageindex:1,
				pageTotal: 0,
				fullscreenLoading: false,
				url: [{
						urla: 'http://static.woquguo.net/images/youji/383f4f5269394fa8ae951a7f30776c4b.jpg',
						urlb: '最想环游的世界——华丽丽的澳大利亚巡游',
						urlc: 'by  邓玉茹！',
						id: 0
					},
					{
						urla: 'http://static.woquguo.net/images/youji/bd00bd90d9484a6e893a4b43a897bc75.jpg',
						urlb: '和娜女神首次出国，泰好玩的流水账！',
						urlc: 'by  噔噔噔噔！',
						id: 1
					},
					{
						urla: 'http://static.woquguo.net/images/youji/9b7aa73ea54e4cdaa20f675c60840574.jpg',
						urlb: '台湾环岛游11天之旅',
						urlc: 'by  黄同伟！',
						id: 2
					},
					{
						urla: 'http://static.woquguo.net/images/youji/71b19480fdb14edea785eb889fa5ff2e.jpg',
						urlb: '独自行我路（美因河畔法兰克福）',
						urlc: 'by  李永聪',
						id: 3
					}
				],
				xuanze: ['目的地', '玩法', '月份', '天数', '和谁出行'],
				mudid: 0,
				mudi: [{
						guo: '全部'
					},
					{
						guo: '广东',
						nei: '（33）'
					},
					{
						guo: '台湾',
						nei: '（6）'
					},
					{
						guo: '湖南',
						nei: '（4）'
					},
					{
						guo: '香港特别行政区',
						nei: '（4）'
					},
					{
						guo: '广西',
						nei: '（3）'
					},
					{
						guo: '澳门',
						nei: '（2）'
					},
					{
						guo: '福建',
						nei: '（2）'
					},
					{
						guo: '黑龙江',
						nei: '（2）'
					},
					{
						guo: '云南',
						nei: '（2）'
					},
					{
						guo: '北京市',
						nei: '（2）'
					},
					{
						guo: '江苏',
						nei: '（2）'
					},
					{
						guo: '贵州',
						nei: '（2）'
					},
					{
						guo: '河南',
						nei: '（1）'
					},
					{
						guo: '山东',
						nei: '（1）'
					},
					{
						guo: '内蒙古',
						nei: '（1）'
					},
					{
						guo: '浙江',
						nei: '（1）'
					},
					{
						guo: '上海',
						nei: '（1）'
					},
					{
						guo: '安徽',
						nei: '（1）'
					},
					{
						guo: '四川',
						nei: '（1）'
					}
				],
				mudie: 0,
				mudia: [{
						guo: '全部'
					},
					{
						guo: '北美洲',
						nei: '（14）'
					},
					{
						guo: '东南亚',
						nei: '（12）'
					},
					{
						guo: '东北亚',
						nei: '（6）'
					},
					{
						guo: '欧洲',
						nei: '（6）'
					},
					{
						guo: '南美洲',
						nei: '（3）'
					},
					{
						guo: '大洋洲',
						nei: '（2）'
					},
					{
						guo: '非洲',
						nei: '（1）'
					}
				],
				wanfa: 0,
				wanf: [{
						guo: '全部'
					},
					{
						guo: '摄影',
						nei: '（33）'
					},
					{
						guo: '美食',
						nei: '（6）'
					},
					{
						guo: '文化',
						nei: '（4）'
					},
					{
						guo: '海滩',
						nei: '（4）'
					},
					{
						guo: '都市',
						nei: '（3）'
					},
					{
						guo: '购物',
						nei: '（2）'
					},
					{
						guo: '历史',
						nei: '（2）'
					},
					{
						guo: '建筑',
						nei: '（2）'
					},
					{
						guo: '古镇',
						nei: '（2）'
					},
					{
						guo: '岛屿',
						nei: '（2）'
					},
					{
						guo: '徒步',
						nei: '（2）'
					},
					{
						guo: '登山',
						nei: '（2）'
					},
					{
						guo: '主题公园',
						nei: '（1）'
					},
					{
						guo: '艺术',
						nei: '（1）'
					},
					{
						guo: '亲子',
						nei: '（1）'
					},
					{
						guo: '潜水',
						nei: '（1）'
					},
					{
						guo: '寺庙',
						nei: '（1）'
					},
					{
						guo: '蜜月',
						nei: '（1）'
					},
					{
						guo: '湖泊',
						nei: '（1）'
					},
					{
						guo: '节庆',
						nei: '（1）'
					},
					{
						guo: '赏花',
						nei: '（1）'
					},
					{
						guo: '森林',
						nei: '（1）'
					},
					{
						guo: '动物',
						nei: '（1）'
					},
					{
						guo: '名山',
						nei: '（1）'
					},
					{
						guo: '湿地',
						nei: '（1）'
					},
					{
						guo: '峡谷',
						nei: '（1）'
					},
					{
						guo: '温泉',
						nei: '（1）'
					},
					{
						guo: '洞穴',
						nei: '（1）'
					},
					{
						guo: '草原',
						nei: '（1）'
					}
				],
				yuea: 0,
				yue: [{
						guo: '全部'
					},
					{
						guo: '1-2月'
					},
					{
						guo: '3-4月'
					},
					{
						guo: '5-6月'
					},
					{
						guo: '7-8月'
					},
					{
						guo: '9-10月'
					},
					{
						guo: '11-12月'
					}
				],
				tiana: 0,
				tian: [{
						guo: '全部'
					},
					{
						guo: '1-3天'
					},
					{
						guo: '4-7天'
					},
					{
						guo: '8-10天'
					},
					{
						guo: '11-15天'
					},
					{
						guo: '15天以上'
					}
				],
				hea: 0,
				he: [{
						guo: '全部'
					},
					{
						guo: '单人'
					},
					{
						guo: '朋友'
					},
					{
						guo: '情侣'
					},
					{
						guo: '夫妻'
					},
					{
						guo: '亲子'
					},
					{
						guo: '父母'
					},
					{
						guo: '其他'
					}
				],
				faba: 0,
				fab: ['最新发表', '热门游记', '精品游记'],
				ArticleList:null,
				showLoading:false
			};
		},
		created() {
			that = this;
			GetArticleListByUserCode();
			$(window).scrollTop("0");
		},
		methods: {
			//游记跳转
			bindJumpYouji: function(e) {
				// window.open("http://youji.woquguo.net/Article/" + e.ID + "");
				window.open("#/youji/article?id=" + e.ID + "");
			},
			//分页
			handleCurrentChange(page) {
				console.log(`当前页: ${page}`);
				that.pageindex = page;
				GetArticleListByUserCode();
			},
			// prevpage: function(e) {
			// 	// 分页 上一页事件
			// 	this.pageindex = e
			// 	console.log(this.pageindex)
			// 	const loading = this.$loading({
			// 		lock: true,
			// 		text: '加载中...',
			// 	});
			// 	setTimeout(() => {
			// 		loading.close();
			// 	}, 2000);
			// 	GetArticleListByUserCode();
			// },
			// nextpage: function(e) {
			// 	// 分页 下一页事件
			// 	this.pageindex = e
			// 	console.log(this.pageindex)
			// 	const loading = this.$loading({
			// 		lock: true,
			// 		text: '加载中...',
			// 	});
			// 	setTimeout(() => {
			// 		loading.close();
			// 	}, 2000);
			// 	// if(this.Author.length=0){
			// 	// 	console.log('没数据了')
			// 	// }
			// 	GetArticleListByUserCode();
			// },
			// curchange: function(e) {
			// 	// 分页 中间数字选中事件
			// 	this.pageindex = e
			// 	console.log(this.pageindex)
			// 	const loading = this.$loading({
			// 		lock: true,
			// 		text: '加载中...',
			// 	});
			// 	setTimeout(() => {
			// 		loading.close();
			// 	}, 2000);
			// 	GetArticleListByUserCode();
			// },
			wangchu(e) {
				this.wan = e
				// 游记轮播图事件
				// console.log(this.wan)
			},
			btn(index) {
				// 游记轮播图右边小图<轮播图>
				this.$refs.carousel.setActiveItem(index);
				this.wan = index
				this.autoplay = false
				console.log(index)
			},
			bttn(index) {
				// 游记轮播图右边小图<轮播图>
				this.autoplay = true
			},
			btnn(index) {
				// 游记-所有分类选项卡中的 目的地内容国内选择事件
				this.mudid = index
				console.log(index)
			},
			btnnn(index) {
				// 游记-所有分类选项卡中的 目的地内容国际选择事件
				this.mudie = index
				console.log(index)
			},
			btu(index) {
				// 游记-所有分类选项卡中的 玩法内容选择事件
				this.wanfa = index
				console.log(index)
			},
			btuu(index) {
				// 游记-所有分类选项卡中的 月份内容选择事件
				this.yuea = index
				console.log(index)
			},
			btuuu(index) {
				// 游记-所有分类选项卡中的 天数内容选择事件
				this.tiana = index
				console.log(index)
			},
			btuuuu(index) {
				// 游记-所有分类选项卡中的 和谁出现内容选择事件
				this.hea = index
				console.log(index)
			},
			btnf(index) {
				console.log(index);
				// 全部游记下方 选项卡 最新发表 热门游记 精品游记 选择事件
				that.faba = index
				that.pageindex=1;
				that.currentPage=1;
				if (index == 1) {
					that.OrderBy = 3
					
				}
				else if (index == 2) {
					that.OrderBy = 99
					
				}
				else{
					that.OrderBy = 1
				}
				GetArticleListByUserCode();
			}
		}
	};

	function GetArticleListByUserCode() {
		that.showLoading=true;
		// 游记内容接口
		var parm = {
			Continent: '', //洲代码
			//PageIndex:1,//第几页（默认1开始）
			PageSize: that.pageSize, //总页数
			OrderBy: that.OrderBy, //排序方式(1、创建时间 2、浏览量 3、评论数 4、点赞数 99、无序 默认为按创建时间)
			PageIndex: that.pageindex
		};
		wqgApi.GetArticleListByUserCode(parm, function(result) {
			that.showLoading=false;
			console.log(result);
			if (result.code == 0) {
				that.ArticleList = result.data.ArticleList
				that.pageTotal = result.data.RowCount
				console.log(result.data.ArticleList)
				console.log(parm)
				console.log('游记内容接口')
			}
			else{
				
			}
		})
	};
	// 定义瀑布流算法函数
	function fall() {
		const minGap = 20; // 最小间距，让每一列的最小空隙可以自定义，避免太过拥挤的情况发生。但是，会通过计算得到真实的间距。
		const itemWidth = 300; // 每一项的宽度，即当前每一个图片容器的宽度。保证每一列都是等宽不等高的。
		const scrollBarWidth = getScrollbarWidth(); // 获取滚动条的宽度
		const pageWidth = window.innerWidth - scrollBarWidth; // 获取当前页面的宽度 = window.innerWidth - 滚动条的宽度
		const column = Math.floor(pageWidth / (itemWidth + minGap)); // 实际列数=页面宽度/(图片宽度+最小间距)
		const gap = (pageWidth - itemWidth * column) / column / 2; // 计算真实间距 = (页面宽度- 图片宽度*实际列数)/实际列数/2
		const items = document.querySelectorAll('img'); // 获取所有的外层元素
		const heightArr = []; // 定义一个空数组，保存最低高度。

		// 获取滚动条的宽度
		function getScrollbarWidth() {
			const oDiv = document.createElement('div'); //创建一个div
			// 给div设置样式。随便定义宽高，只要能获取到滚动条就可以
			oDiv.style.cssText = `width: 50px;height: 50px;overflow: scroll;`
			document.body.appendChild(oDiv); //把div添加到body中
			const scrollbarWidth = oDiv.offsetWidth - oDiv.clientWidth; // 使最大宽度和可视宽度相减，获得到滚动条宽度。
			oDiv.remove(); //移除创建的div
			return scrollbarWidth; //返回滚动条宽度
		}


		for (let i = 0; i < items.length; i++) {
			// 遍历所有的外层容器
			const height = items[i].offsetHeight;
			// 如果当前处在第一行
			if (i < column) {
				// 直接设置元素距离上部的位置和距离左边的距离。
				items[i].style.cssText = `top: ${gap}px;left: ${(itemWidth + gap) * i + gap}px`;
				// 保存当前元素的高度。
				heightArr.push(height);
			} else {
				// 不是第一行的话，就进行比对。
				let minHeight = heightArr[0]; // 先保存第一项的高度
				let minIndex = 0; // 保存第一项的索引值
				for (let j = 0; j < heightArr.length; j++) {
					// 通过循环遍历比对，拿到最小值和最小值的索引。
					if (minHeight > heightArr[j]) {
						minHeight = heightArr[j];
						minIndex = j;
					}
				}
				// 通过最小值为当前元素设置top值，通过索引为当前元素设置left值。
				items[i].style.cssText = `top: ${minHeight + gap *2}px; left: ${(itemWidth + gap) * minIndex + gap}px`;
				// 并修改当前索引的高度为当前元素的高度
				heightArr[minIndex] = minHeight + gap + height;
			}
		}
	}
	// 页面加载完成调用一次。
	window.onload = fall;
	// 页面尺寸发生改变再次调用。
	window.onresize = fall;
</script>

<style lang="scss">
	@import '../../style/youji/list.scss';
</style>
